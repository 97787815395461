import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
              <p>{props.data ? props.data.paragraph3 : "loading..."}</p>
              <p>{props.data ? props.data.paragraph4 : "loading..."}</p>
              {/* <h3>Vision</h3> */}
              {/* <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            {" "}
            <div className="vision-title about-text" >
              <h2> 
                Vision
              </h2>
              <p>At MachTech International, our vision is to empower businesses of all sizes and industries to thrive. We envision a future where we stand side by side with our clients, serving as strategic partners in their journey towards sustainable growth and success.</p>
            </div>
            <img src="img/abtimg.jpg" className="img-responsive" alt="" />{ " " }
          </div>
        </div>
      </div>
    </div>
  );
};
