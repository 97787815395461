import { useState } from "react";
import React from "react";
import { useFormik } from "formik";
import emailjs from "emailjs-com";

const initialValues = {
  fname: "",
  lname: "",
  email: "",
  contact: "",
  shifts: "yes",
};

const onSubmit = ( values ) =>
{
  console.log( "Form data", values );
};

export const Career = ( props ) =>
{
  const [ { fname, lname, email, contact, shifts }, setState ] = useState( initialValues );
  const formik = useFormik( {
    initialValues,
    onSubmit,
  } );
  const handleChange = ( e ) =>
  {
    const { name, value } = e.target;
    setState( ( prevState ) => ( { ...prevState, [ name ]: value } ) );
  };
  const clearState = () => setState( { ...initialValues } );

  const handleSubmit = ( e ) =>
  {
    e.preventDefault();

    // console.log( name, email, message );
    emailjs
      .sendForm( "Career_form", "template_my4xu8j", e.target, "juIliwXLiMO3vbuM6" )
      .then(
        ( result ) =>
        {
          console.log( result.text );
          alert( "Form submitted successfully!" );
          clearState();
        },
        ( error ) =>
        {
          console.log( error.text );
          alert( "Form submission failed. try again!" );
        }
      );
  };

  // const handleFile = ( e ) =>
  // {
  //   formik.setFieldValue( "cv", e.target?.files[ 0 ] );
  // };

  // const handleRadioCheck = ( e ) =>
  // {
  //   formik.setFieldValue( "shifts", e.target.value );
  // };

  return (
    <div>
      <div id="careers">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Start your career</h2>
                <p>
                  Please fill out the form and attach your resume to get your
                  career started.
                </p>
              </div>

              <form name="sentMessage" validate onSubmit={ handleSubmit }>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        className="form-control"
                        placeholder="First Name"
                        required
                        onChange={ handleChange }
                        // value={ formik.values.fname }
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={ handleChange }
                        // value={ formik.values.email }
                      />
                    </div>
                    <div className="form-group shiftlabel">
                      <label>
                        Is it convenient to work in Rotational shifts?
                      </label>
                      <br/>
                    </div>
                    <div className="form-group shiftlabel">
                      <label>
                        Share your resume via: 
                      </label>
                      <br/>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="lname"
                        name="lname"
                        className="form-control"
                        placeholder="Last Name"
                        required
                        onChange={ handleChange }
                        // value={ formik.values.lname }
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        id="contact"
                        name="contact"
                        className="form-control"
                        placeholder="Contact Number"
                        required
                        onChange={ handleChange }
                        // value={ formik.values.contact }
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="text"
                        id="shifts"
                        name="shifts"
                        className="form-control"
                        placeholder="(Yes/No)"
                        required
                        onChange={ handleChange }
                        // value={ formik.values.lname }
                      />
                     
                    </div>
                    <div className="form-group">
                      {/* <label className="clickhere">  */}
                      <a href="https://wa.me/923303333334" id="contactlink">
                        Click Here
                        <img className="wimg-container" src="img/whatsapp.png" alt="whatsapp"></img>
                      </a>
                      {/* </label> */}
                    </div>
                  </div>
                </div>

                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <br></br>
    </div>
  );
};


